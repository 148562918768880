interface IConfig {
  prodUrl: string;
  appType: 'portals' | 'internal_apps' | 'video';
}

export const appConfig: IConfig = {
  // Used to determine when to send prod Quantum analytics
  prodUrl: 'offer.spectrum.net',
  // Used to determine which Quantum URLs to hit
  appType: 'portals',
};
