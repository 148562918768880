import packageJson from '../../../package.json';
import { appConfig } from '../../config/appConfig';

// https://prism.spectruminternal.com/documentation/quantum/Quantum/home
export default {
  domain: appConfig.appType,
  customer: 'Charter',
  appName: 'SpectrumOffer',
  appType: 'Web',
  appVersion: packageJson.version,
  deviceType: 'webBrowser',
  /**
   * ensure requirementsVersion up-to-date by checking:
   * https://prism.spectruminternal.com/SolutionMappingToolAdmin@Web/quantum-resources/helix/release-notes?hideRC=true&platform=JS
   * */
  requirementsVersion: '2.607',
  msgTriggeredBy: 'user',
  startSession: 'SpectrumOffer_startSession_generic',
  settings: {
    logLevel: 'error',
  },
};
