import React, { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

// Components
import { KiteProgressIndicator } from '@kite/react-kite';
import Footer from '../Footer';
import Header from '../Header';
import { NotFound } from '../../pages';

// Styles
import './Layout.scss';

export interface ILayoutProps {
  className?: string;
  pathName: string;
}

const Layout = ({ className = '', pathName }: ILayoutProps) => {
  return (
    <div className="layout">
      <Header />
      <main className={className}>
        {pathName === '/' && <NotFound />}
        {pathName !== '/' && <Outlet />}
      </main>
      <Footer />
    </div>
  );
};

export default Layout;
