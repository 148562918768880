// Packages
import React from 'react';
import ReactDOM from 'react-dom';

// Components
import App from './App';

// Contexts
import { QuantumContextProvider } from './contexts';

// Styles
import './index.css';

const root = document.getElementById('root');

if (!root) {
  throw new Error('Failed to find the root element');
}

ReactDOM.render(
  <React.StrictMode>
    <QuantumContextProvider>
      <App />
    </QuantumContextProvider>
  </React.StrictMode>,
  root
);
