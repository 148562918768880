// Packages
import React, { useEffect, useState } from 'react';
import { useClearCacheCtx } from 'react-clear-cache';
import { BrowserRouter as Router } from 'react-router-dom';
import { KiteProgressIndicator } from '@kite/react-kite';

// Components
import Routes from './routes/Routes';

// Styles
import './App.scss';

const App = () => {
  const { isLatestVersion, emptyCacheStorage } = useClearCacheCtx();
  const [versionCheck, setVersionCheck] = useState(false);

  useEffect(() => {
    document.body.classList.add("kite-theme");
  }, []);

  useEffect(() => {
    (async () => {
      try {
        if (!isLatestVersion) {
          await emptyCacheStorage();
        }
        setVersionCheck(true);
      } catch (error) {
        setVersionCheck(true);
      }
    })();
  }, [isLatestVersion, emptyCacheStorage]);

  if (!versionCheck) {
    return <KiteProgressIndicator id="version check" />;
  }

  return (
    <div className="App">
      <Router>
        <Routes />
      </Router>
    </div>
  );
};

export default App;
